import styles from "./Home.module.css";
import { Link } from "react-router-dom";


export default function PrivacyPolicy(props) {
  return (
    <div className={styles.container}>
      {/*<head>
        <title>ChessNFT</title>
        <meta name="description" content="ChessNFT" />
        <link rel="icon" href="/favicon.ico" />
      </head>*/}

      <main className={styles.main}>
        <div className={styles.grid}>
          <Link to="/" >
          <img src="/chezzbit_2_Logo.png" style={{width: "340px", cursor: "pointer"}} alt="Chezzbit Logo" />
          </Link>

          <div style={{opacity: 1, marginTop: "30px", marginBottom: "10px", fontWeight: "normal"}}></div>

          <div style={{opacity: 1, marginTop: "0px", marginBottom: "0px", fontWeight: "normal" }}>
      <pre style={{whiteSpace: "pre-line", textAlign: "left"}}>
Chezzbit (hereinafter “Chezzbit”, “we” or “us”), represented by fctb. GmbH and managing director Dirk Emminger, operates the domain www.chezzbit.de & chezzbit.com (the “website”).<br/>
<br/>
Chezzbit protects the privacy of all website users and strictly complies with all applicable data protection provisions, including the European general data protection regulation (Datenschutzgrundverordnung) (“GDPR“) and the German telemedia act (Telemediengesetz).<br/>
<br/>
For any questions or requests regarding data protection, please contact us under info@chezzbit.com<br/>
<br/>
The Personal Data we collect from you depends on your specific relationship with us. Below we describe what kind of data we collect, where it comes from, the purpose and the legal basis of the processing, its duration, and the recipients with whom the data is shared. One or several of the following specific situations may apply to you:<br/>
<br/>
Processing when using our Services (such as Website, App)<br/>
<br/>
Data concerned<br/>
<br/>
When accessing our website, our App or using other Services we, ourselves or our web hosting provider, collect data on the basis of each access to the server (so-called server log files). Server log files may include the following information:<br/>
<br/>
- the browser types and versions used;<br/>
- the operating system used by the accessing system;<br/>
- the date and time of an access;<br/>
- the pages of our service that you visit;<br/>
- referrer URL (the previously visited page);<br/>
- the Internet Protocol (IP) address of your device;<br/>
<br/>
When you access the Service by or through a mobile device or App, we may collect the following information automatically,<br/>
<br/>
- the type of mobile device you use;<br/>
- your mobile device unique ID;<br/>
- the IP address of your mobile device;<br/>
- your mobile operating system;<br/>
- the type of mobile internet browser;<br/>
- unique device identifiers and other diagnostic data.<br/>
<br/>
The purpose of processing and legal basis<br/>
<br/>
We use the collected data (the type of mobile device you use,your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browser, unique device identifiers and other diagnostic data) to provide contractual services and customer support, deliver and optimize the content of our Services correctly and to ensure the long-term viability and technical security of our systems. This purpose constitutes our legitimate interest in data processing pursuant to art. 6 para. 1 lit. f GDPR.<br/>
<br/>
Personal data that you voluntarily submit to us, e.g. via email or a contact form, will be stored for the purpose of processing or for contacting you. The legal basis for such processing is art. 6 para. 1 lit. f GDPR.<br/>
<br/>
Sharing your data<br/>
<br/>
We will share your data with third parties who store your data on their servers. The types of third parties with whom we share your data include:<br/>
<br/>
- IT service providers: including cloud providers for data storage purposes;<br/>
- External support service providers;<br/>
- Public bodies and authorities if the appropriate legal provisions exist (e.g. tax authorities and customs authorities) on the basis of art. 6 para 1 lit. c GDPR.<br/>
<br/>
Duration of storage<br/>
<br/>
We store your personal data only as long as it is necessary to achieve the purpose of processing. If we use your personal data on the basis of a legitimate interest, we will store it at most as long as your interest in deletion or anonymisation does not prevail. In addition, data may be stored if this has been provided for by the European Union or national legislator in regulations, laws or other provisions to which the person responsible is subject. The duration of the data storage depends on the statutory storage obligations and is generally 10 years.<br/>
<br/>
Cookies<br/>
<br/>
Cookies are text files which are stored on a computer system via an internet browser. Cookies are primarily used to store information about a user during or after his visit within an online service. The information stored can include, for example, the language settings on a website, the login status or a shopping basket. The term "cookies" hereinafter also includes other technologies that fulfil the same functions as cookies (e.g. if user information is stored using online identifiers, also referred to as "user IDs").<br/>
<br/>
The following types and functions of cookies are distinguished:<br/>
<br/>
- Temporary cookies (also: session cookies): Temporary cookies are deleted at the latest after a user has left an online service and closed his browser.<br/>
- Permanent cookies: Permanent cookies remain stored even after closing the browser. For example, the login status can be saved or preferred content can be displayed directly when the user visits a website again. The interests of users who are used for range measurement or marketing purposes can also be stored in such a cookie.<br/>
- First-Party-Cookies: First-Party-Cookies are set by ourselves.<br/>
- Third party cookies: Third party cookies are mainly used by advertisers (so-called third parties) to process user information.<br/>
- Necessary (also: essential) cookies: Cookies can be necessary for the operation of a website (e.g. to save logins or other user inputs or for security reasons).<br/>
- Statistics, marketing and personalisation cookies: Cookies are also generally used to measure a website's reach and when a user's interests or behaviour (e.g. viewing certain content, using functions, etc.) are stored on individual websites in a user profile. Such profiles are used, for example, to display content to users that corresponds to their potential interests. This procedure is also referred to as "tracking", i.e. tracking the potential interests of users.<br/>
<br/>
Legal basis for the use of cookies<br/>
<br/>
The legal basis on which we process your Personal Data with the help of cookies depends on whether we ask you for your consent. If this applies and you consent to the use of cookies, the legal basis for processing your data is your declared consent according to art. 6 para. 1 lit. a GDPR or art. 9 para. 2 lit. a GDPR.<br/>
<br/>
Otherwise, the data processed with the help of cookies will be processed on the basis of our legitimate interests according to art. 6 para. 1 lit. f GDPR (e.g. in a business operation of our online service and its improvement) or, if the use of cookies is necessary to fulfill our contractual obligations according to art. 6 para. 1 lit. b GDPR.<br/>
<br/>
Duration of storage<br/>
<br/>
Unless we provide you with explicit information on the retention period of permanent cookies (e.g. within the scope of a so-called cookie opt-in), the retention period is as long as it is necessary to achieve the purpose of the processing.<br/>
<br/>
General information on withdrawal of consent and objection (Opt-Out)<br/>
<br/>
Respective of whether processing is based on consent or another legal basis, you have the option at any time to object to the processing of your data using cookie technologies or to revoke consent (collectively referred to as "opt-out"). You can initially explain your objection using the settings of your browser, e.g. by deactivating the use of cookies (which may also restrict the functionality of our Services).<br/>
<br/>
Processing Cookie Data on the Basis of Consent<br/>
<br/>
We use a cookie management solution in which users' consent to the use of cookies, or the procedures and providers mentioned in the cookie management solution, can be obtained, managed and revoked by the users. The declaration of consent is stored so that it does not have to be retrieved again and the consent can be proven in accordance with the legal obligation. Storage can take place server-sided and/or in a cookie (so-called opt-out cookie or with the aid of comparable technologies) in order to be able to assign the consent to a user or and/or his/her device.<br/>
<br/>
Social Media<br/>
<br/>
We maintain online presences within social networks and process user data in this context in order to communicate with the active users or offer information about us.<br/>
<br/>
When you visit or interact with a profile on a social media platform, Personal Data about you may be processed. Also information associated with a social media profile regularly represent Personal Data. This also includes messages and statements made using the profile. In addition, during your visit to a social media profile, certain information is often automatically collected, which may also represent Personal Data.<br/>
<br/>
Data concerned<br/>
<br/>
Inventory data (e.g. names, addresses), contact data (e.g. e-mail, telephone numbers), content data (e.g. entries in online forms), usage data (e.g. websites visited, interest in content, access times), meta/communication data (e.g. device information, IP addresses).<br/>
<br/>
The purpose of processing and legal basis<br/>
<br/>
Contact requests and communication, tracking (e.g. interest/behavioural profiling, use of cookies), remarketing, reach measurement (e.g. access statistics, recognition of returning visitors). The legal basis for this is Art. 6 (1) lit. f GDPR.<br/>
<br/>
Sharing your Data<br/>
<br/>
For a detailed presentation of the respective forms of processing and the possibilities of objection (opt-out), we refer to the data protection policies and information provided by the operators of the respective networks.<br/>
<br/>
Also in the case of requests for information and the assertion of data subject rights, we advise that these can most effectively be asserted with the providers. Only the providers have access to the data of the users and can take appropriate measures and provide information directly.<br/>
<br/>
Service providers being used:<br/>
<br/>
- Instagram: Social network; Service provider: Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA, , Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website: https://www.instagram.com; Privacy Policy: https://instagram.com/about/legal/privacy.<br/>
- Facebook: Social network; Service provider: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, parent company: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website: https://www.facebook.com; Privacy Policy: https://www.facebook.com/about/privacy; Opt-Out: Settings for advertisements: https://www.facebook.com/settings?tab=ads.<br/>
- LinkedIn: Social network; Service provider: LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland; Website: https://www.linkedin.com; Privacy Policy: https://www.linkedin.com/legal/privacy-policy; Opt-Out: https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.<br/>
- Twitter: Social network; Service provider: Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Ireland, parent company: Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA; Privacy Policy: https://twitter.com/de/privacy, (Settings) https://twitter.com/personalization.<br/>
- YouTube: Social network and video platform; Service provider: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Privacy Policy: https://policies.google.com/privacy; Opt-Out: https://adssettings.google.com/authenticated.<br/>
<br/>
Your rights<br/>
<br/>
According to data protection legislation, you may have a number of rights regarding the data we hold about you. If you wish to exercise any of these rights, please contact us at the contact details set out above.<br/>
<br/>
- The right to be informed. You have the right to be provided with clear, transparent and easily understandable information about how we use your data and what your rights are according to the provisions of art. 13 and 14 GDPR. For this reason, we provide you with the information in this Data Protection Notice.<br/>
- The right of access. You have the right to access your data (if we process it) according to the provisions of art. 15 GDPR. This will enable you, for example, to check that we use your data in accordance with data protection law.<br/>
- The right to rectification. You have the right to have your data corrected if it is inaccurate or incomplete according to the provisions of art. 16 GDPR. You may request that we rectify any errors in the data we hold.<br/>
- The right to erasure. This "right to be forgotten" enables you to request the deletion or removal of certain data that we have stored about you according to the provisions of art. 17 GDPR. This right is not absolute and only applies in certain circumstances.<br/>
- The right to restrict processing (blocking of data). You have the right to “block” or “restrict” the further use of your data according to the provisions of art. 18 GDPR. If processing is restricted, we may still store your data, but will not process it further.<br/>
- The right to data portability. You have the right to obtain your Personal Data in an accessible and transferable format so that you can re-use it for your own purposes across different service providers according to the provisions of art. 20 GDPR. However, this is not an absolute right and there are exceptions.<br/>
- The right to lodge a complaint. You have the right to lodge a complaint about the way we handle or process your information with a competent data protection authority according to art. 77 GDPR.-<br/>
- The right to withdraw consent. You have the right to withdraw any consent given to us (if we rely on the consent as a legal basis for the processing of certain data) at any time with effect for the future according to art. 7 GDPR. The legality of the processing carried out on the basis of the consent prior to the withdrawal remains unaffected.<br/>
- The right to object to processing. You have the right to object to the processing of Personal Data concerning you based on art. 6 para. 1 e or f GDPR according to art. 21 GDPR. This also applies to direct marketing and related profiling.<br/>
<br/>
Specific Information on the right to object pursuant to art. 21 (4) GDPR<br/>
<br/>
You have the right to object, on grounds relating to your particular situation, at any time to processing of personal data concerning you which is based on point (e) or (f) of Article 6(1) GDPR, including profiling based on those provisions. We shall no longer process the personal data unless the controller demonstrates compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject or for the establishment, exercise or defence of legal claims. Where personal data are processed for direct marketing purposes, you shall have the right to object at any time to processing of personal data concerning you for such marketing, which includes profiling to the extent that it is related to such direct marketing.<br/>
      </pre>
          </div>


        </div>
      </main>
    </div>
  );
};
