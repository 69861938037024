import { useState, useEffect } from "react";
import styles from "./Home.module.css";

import { useLocation, Link } from "react-router-dom";

import { Chessboard } from "react-chessboard";
import * as ChessJS from "chess.js";
const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess;
const chess = new Chess();


export default function Preview(props) {
  const {state} = useLocation();

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");

  function updateEmail(evt) {
    setEmail(evt.target.value);
  }

  function signup() {
    var form = new FormData();
    form.append("email", email);
    form.append("fen", state.fen);

    fetch('https://chessapi.chezzbit.com/signup', {
      method: 'POST',
      body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      console.log(resp);
      setSuccess("Congratulations! You have been added to the exclusive pre-launch mint club to purchase our Chess NFTs.");
    });
  }

  useEffect(() => {
    console.log(state);
  }, []);

  return (
    <div className={styles.container}>
      {/*<head>
        <title>ChessNFT</title>
        <meta name="description" content="ChessNFT" />
        <link rel="icon" href="/favicon.ico" />
      </head>*/}

      <main className={styles.main}>
        <div className={styles.grid}>
          <Link to="/" >
          <img src="/chezzbit_2_Logo.png" style={{width: "340px", cursor: "pointer"}} alt="Chezzbit Logo" />
          </Link>

          <div style={{opacity: 1, marginTop: "30px", marginBottom: "10px", fontWeight: "normal"}}></div>

          <Chessboard id="chessboard" arePiecesDraggable={false} position={(state||{}).fen} customBoardStyle={{border: "2px solid black", borderRadius: "0px", padding: "10px", display: "inline-table", background: "white"}} boardWidth={320} />

          <div style={{opacity: 1, marginTop: "30px", marginBottom: "00px", fontWeight: "normal", overflow: "scroll"}}>FEN number:</div>
          <div style={{opacity: 1, marginTop: "0px", marginBottom: "10px", fontWeight: "normal", wordBreak: "break-all"}}>{(state||{}).fen}</div>

          <div style={{opacity: 1, marginTop: "10px", marginBottom: "0px", fontWeight: "normal"}}><b>Owned by wallet</b></div>
          <div style={{opacity: 1, marginTop: "0px", marginBottom: "10px", fontWeight: "normal", wordBreak: "break-all"}}><span>You are in luck, your Chess NFT has not been minted yet!</span></div>
          <div style={{opacity: 1, marginTop: "0px", marginBottom: "0px", fontWeight: "normal", wordBreak: "break-all"}}><b>Exclusive pre-launch mint club</b></div>
          <div style={{opacity: 1, marginTop: "0px", marginBottom: "10px", fontWeight: "normal", wordBreak: "break-all"}}>Sign up with your email to be invited to our exclusive pre-launch mint group before we launch.</div>

          <input onChange={updateEmail} value={email} type="email" style={{width: "340px", padding: "5px"}}></input>

          <div style={{display: "flex", alignItems: "center" }}>
            <input type="checkbox" style={{padding: "5px", color: "black", marginRight: "5px" }}></input>
            <span>By submitting your E-Mail you accept our <Link to="/privacy" style={{color: "blue"}}>privacy policy</Link></span>
          </div>

          <span style={{color: "green"}}>{success}</span>

          <div className={styles.card} style={{ width: "100%", marginTop: "10px", paddingTop: "10px" }}>
            <div className={styles.centered}>
            <button onClick={signup} className={styles.mainbutton} style={{paddingTop: "5px"}}>
              Sign up
            </button>
            </div>
          </div>

          <br/>
          <br/>
          <br/>
          Copyright 2022 © All rights reserved. ChezzBit<br/><br/>
          <div style={{display: "flex", alignItems: "center" }}>
          <Link to="/notice">LEGAL NOTICE</Link> <span style={{marginLeft: "10px", marginRight: "10px"}}>|</span> <Link to="/privacy">DATA PRIVACY</Link>
          </div>
        </div>
      </main>
    </div>
  );
};
