import './App.css';
import Home from './main.js';
import Verify from './verify.js';
import Success from './success.js';
import Preview from './preview.js';
import LegalNotice from './legalnotice.js';
import PrivacyPolicy from './privacypolicy.js';
import NotFound from './notfound.js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="bg-white overflow-hidden rounded-lg">
          <div className="px-100 py-10 sm:p-10">
            <Routes>
              <Route path="/" element={<Home/>}></Route>
              <Route path="/:fen/" element={<Home/>}></Route>
              <Route path="/verify" element={<Verify/>}></Route>
              <Route path="/success" element={<Success/>}></Route>
              <Route path="/preview" element={<Preview/>}></Route>
              <Route path="/notice" element={<LegalNotice/>}></Route>
              <Route path="/privacy" element={<PrivacyPolicy/>}></Route>
              <Route path="*" element={<NotFound/>}></Route>
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
