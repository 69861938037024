import styles from "./Home.module.css";
import { Link } from "react-router-dom";


export default function NotFound(props) {
  return (
    <div className={styles.container}>
      {/*<head>
        <title>ChessNFT</title>
        <meta name="description" content="ChessNFT" />
        <link rel="icon" href="/favicon.ico" />
      </head>*/}

      <main className={styles.main}>
        <div className={styles.grid}>
          <Link to="/" >
          <img src="/chezzbit_2_Logo.png" style={{width: "340px", cursor: "pointer"}} alt="Chezzbit Logo" />
          </Link>

          <div style={{opacity: 1, marginTop: "30px", marginBottom: "10px", fontWeight: "normal"}}></div>

          <div style={{opacity: 1, marginTop: "0px", marginBottom: "0px", fontWeight: "normal" }}>
      <pre style={{whiteSpace: "pre-line", textAlign: "left"}}>

      404 - not found

      </pre>
          </div>


        </div>
      </main>
    </div>
  );
};
