import { useState, useCallback, useEffect } from "react";
import styles from "./Home.module.css";
import { useLocation, Link } from "react-router-dom";

import { Chessboard } from "react-chessboard";
import * as ChessJS from "chess.js";
const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess;
const chess = new Chess();


export default function Success(props) {
  const {state} = useLocation();

  useEffect(() => {
    console.log(state);
  }, []);

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.grid}>
          <Link to="/">
          <img src="/chezzbit_2_Logo.png" style={{width: "400px", cursor: "pointer"}} alt="Chezzbit Logo"/>
          </Link>

          <div style={{opacity: 1, marginTop: "30px", marginBottom: "10px", fontWeight: "bold"}}>Success!</div>
          <div style={{opacity: 1, marginBottom: "30px", marginTop: "10px"}}>Check out your NFT on <u><a href={(state||{}).url}>OpenSea</a></u> in a few minutes.</div>

          <Chessboard id="chessboard" arePiecesDraggable={false} position={(state||{}).fen} customBoardStyle={{border: "2px solid black", borderRadius: "0px", padding: "10px", display: "inline-table", background: "white"}} boardWidth="380" />

          <div style={{opacity: 0.5, marginTop: "30px", marginBottom: "5px"}}>FEN number</div>
          <input className={styles.input} value={(state||{}).fen} disabled />
          <div className={styles.card} style={{ width: "100%" }}>
            <Link to="/">
            <div className={styles.centered}>
            <button className={styles.mainbutton}>
              Mint another NFT? <span style={{fontSize: "20px", marginTop: "-5px", marginLeft: "6px"}}>&#9813;</span>
            </button>
            </div>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};
