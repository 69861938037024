import { useState, useCallback, useEffect, useRef } from "react";
import styles from "./Home.module.css";

import {
  BrowserRouter as Router,
  useNavigate,
  Link,
  useParams,
} from "react-router-dom";

import { CookieBanner } from "@palmabit/react-cookie-law";
import { ethers } from "ethers";
import ChessNFT from "./ChessNFT.json";
import 'chessboard-element';

const CONTRACT_ADDRESS = "0x05d79fe9a28A23C623E6Aec81C5C252fd7949935";
const abi = ChessNFT.abi;


function Home() {
  const { fen } = useParams();

  const [game, setGame] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const chessRef = useRef();

  let navigate = useNavigate();

  const useForceUpdate = () => {
    const [, setState] = useState();
    return () => setState({});
  }

  const forceUpdate = useForceUpdate();

  function chessUpdate(e) {
    console.log(e);
    console.log(e.target.fen());
    console.log(chessRef.current.fen());
    setGame(chessRef.current.fen());
    forceUpdate();
  }

  function verifyNFT() {
    console.log(chessRef.current.fen());
    navigate(
        '/preview',
        {state: { fen: chessRef.current.fen() }}
    )

//     Router.push({
//       pathname: '/verify',
//       // query: { fen: game.fen() }
//       // query: { fen: fen }
//       query: { fen: game }
//     })
  }

  const handleAddComment = useCallback((comment, ...args) => {
    console.log(comment, args);
    setComments((prevState) => [...prevState, comment]);
  }, []);

  const getEthereumObject = useCallback(() => {
    const { ethereum } = window;

    if (!ethereum) {
      setComments(["Failed to connect to metamask."]);
    } else {
      return ethereum;
    }
  }, []);

  const connectToContract = useCallback(() => {
    const ethereum = getEthereumObject();
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
  }, []);

  const mintBasicNFT = async () => {
    // !!!
    verifyNFT();

    // setIsLoading(true);
    // try {
    //   // handleAddComment("Verifying mint.. Don't reload the page.");
    //   // handleAddComment("Connecting to contract...");
    //   const connectedContract = connectToContract();

    //   // handleAddComment("Encoding FEN..");
    //   // let fen = encodeURIComponent(game.fen()); 
    //   let fen = encodeURIComponent(chessRef.current.fen()); 

    //   // handleAddComment("Going to pop wallet now to pay gas...");
    //   let nftTxn = await connectedContract.mintNFT(fen, 0, {value: ethers.utils.parseEther("0.01")});

    //   handleAddComment(<span>Verifying mint.. Do not reload the page.</span>);
    //   // handleAddComment("Minting...please wait.");
    //   await nftTxn.wait();

    //   // handleAddComment(
    //   //   `Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`
    //   // );

    //   const count = await getTokenCount();
    //   let url = `https://testnets.opensea.io/assets/mumbai/${CONTRACT_ADDRESS}/${count}`

    //   // handleAddComment(
    //   //   `OpenSea: https://testnets.opensea.io/assets/mumbai/${CONTRACT_ADDRESS}/${count}`
    //   // );

    //   navigate(
    //     '/success',
    //     {state: { url: url, fen: chessRef.current.fen() }}
    //   );

    //   // Router.push({
    //   //   pathname: '/success',
    //   //   // query: { url: url, fen: game.fen() }
    //   //   query: { url: url, fen: game }
    //   // })
    // } catch (error) {
    //   // console.log(error);

    //   console.log(JSON.stringify(error));
    //   console.log(error["reason"]);

    //   // if (((error["data"] || {})["message"] || "").includes("scarcity")) {
    //   if (error["reason"].includes("scarcity")) {
    //     const connectedContract = connectToContract();
    //     // const id = await connectedContract.tokenIdByFEN(encodeURIComponent(game.fen()));
    //     const id = await connectedContract.tokenIdByFEN(encodeURIComponent(chessRef.current.fen()));

    //     handleAddComment(<span style={{color: "darkred", fontWeight: "bold"}}>Failed to mint - the NFT exists.</span>);
    //     let url = `https://testnets.opensea.io/assets/mumbai/${CONTRACT_ADDRESS}/${id}`
    //     handleAddComment(
    //       <span>Still want it? Check the <u><a href={url}>secondary market</a></u>.</span>
    //     );
    //   }
    //   // if (error["message"].includes("denied")) {
    //   if (error["reason"].includes("denied")) {
    //     handleAddComment(<span style={{color: "darkred", fontWeight: "bold"}}>Failed to mint - denied transaction.</span>);
    //   }
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const connectWallet = useCallback(async () => {
    const ethereum = getEthereumObject();

    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    if (accounts.length > 0) {
      setCurrentAccount(accounts[0]);
      getTokenCount();
    }
  }, []);

  const getTokenCount = useCallback(async () => {
    try {
      const connectedContract = connectToContract();

      // connectedContract.withdrawAll().then((resp) => {
      //   console.log(resp);
      // });

      // handleAddComment(`Getting NFT count...`);
      const count = await connectedContract.tokenCount();
      return count.toNumber();
    } catch (error) {
      return 0;
    }
  }, []);

  const updateFENfromInput = (e) =>  {
    var fen = e.target.value;
    setGame(decodeURI(fen));
    chessRef.current.setPosition(decodeURI(fen));
  }

  useEffect(() => {
    chessRef.current.addEventListener("change", chessUpdate);
    // connectWallet();
  }, [chessRef, chessUpdate]);

  useEffect(() => {
    console.log(fen);
    if (fen) {
      setGame(decodeURI(fen));
      chessRef.current.setPosition(decodeURI(fen));
    }
  }, []);

  return (
    <div className={styles.container}>
      {/*<head>
        <title>ChessNFT</title>
        <meta name="description" content="ChessNFT" />
        <link rel="icon" href="/favicon.ico" />
      </head>*/}

      <main className={styles.main}>
        <div className={styles.grid}>
          <a href="/">
          <img src="/chezzbit_2_Logo.png" style={{width: "340px", cursor: "pointer"}} alt="Chezzbit Logo"/>
          </a>

          <div style={{opacity: 0.5, marginTop: "30px", marginBottom: "10px"}}>Drag &amp; drop to select your chess position</div>

          <chess-board ref={chessRef} onMouseDown={chessUpdate} onClick={chessUpdate} position="start" draggable-pieces drop-off-board="trash" spare-pieces style={{width: "340px"}}></chess-board>

          <div style={{opacity: 0.5, marginTop: "30px", marginBottom: "5px"}}>FEN number</div>
          <textarea className={[styles.input,styles.textarea].join(" ")} onChange={(e) => {updateFENfromInput(e)}} value={chessRef.current ? chessRef.current.fen() : ""}></textarea>
          <a className={styles.card} style={{ width: "100%" }}>
            <span className={styles.buttongroup}>
           {/*<button className={styles.mainbutton} onClick={currentAccount && !isLoading ? mintBasicNFT : undefined}>
              Mint this NFT <span style={{fontSize: "20px", marginTop: "-5px", marginLeft: "6px"}}>&#9813;</span>
            </button>*/}
            <button className={styles.mainbuttonOutline} onClick={verifyNFT}>
              Verify this NFT <span style={{fontSize: "20px", marginTop: "-5px", marginLeft: "6px"}}>&#9813;</span>
            </button>
            </span>

            <ul style={{width: "180px", margin: "0 auto", opacity: "0.5", fontSize: "13px"}}>
            {comments.map((comment, index) => (
              <li key={index}>{comment}</li>
            ))}
            </ul>
          </a>

          <br/>
          <br/>
          <br/>
          Copyright 2022 © All rights reserved. ChezzBit<br/><br/>
          <div style={{display: "flex", alignItems: "center" }}>
          <Link to="/notice">LEGAL NOTICE</Link> <span style={{marginLeft: "10px", marginRight: "10px"}}>|</span> <Link to="/privacy">DATA PRIVACY</Link>
          </div>

        </div>
      </main>
      <CookieBanner
        message="This site or third-party tools used by this make use of cookies necessary for the operation and useful for the purposes outlined in the cookie policy. To learn more or opt out, see the cookie policy. By accepting, you consent to the use of cookies."
        wholeDomain={true}
        privacyPolicyLinkText="DATA PRIVACY"
        policyLink="https://chezzbit.com/privacy"
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {}}
        onAcceptMarketing={() => {}}
      />
    </div>
  );
};

export default Home;
