import { useState, useCallback, useEffect } from "react";
import styles from "./Home.module.css";
import { Button } from "flowbite-react";

import { useLocation, Link } from "react-router-dom";

import { ethers } from "ethers";
import ChessNFT from "./ChessNFT.json";

import { Chessboard } from "react-chessboard";
import * as ChessJS from "chess.js";
const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess;
const chess = new Chess();


// const CONTRACT_ADDRESS = "0xe26025f81Fd4d99dd8319E41eAba0B54eAe338bA";
// const CONTRACT_ADDRESS = "0x20D74053259194B0a0139648EDBf7f7586f96339";
const CONTRACT_ADDRESS = "0x05d79fe9a28A23C623E6Aec81C5C252fd7949935";
const abi = ChessNFT.abi;


export default function Verify(props) {
  const {state} = useLocation();

  const [owner, setOwner] = useState(<span></span>);
  const [tokenId, setTokenId] = useState(0);
  const [showOpenSea, setShowOpenSea] = useState(false);

  const getEthereumObject = useCallback(() => {
    const { ethereum } = window;
    if (!ethereum) {
      throw new Error("Ethereum object doesn't exist!");
    } else {
      return ethereum;
    }
  }, []);

  const connectToContract = useCallback(() => {
    const ethereum = getEthereumObject();
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
  }, []);

  const getOwner = async (fen) => {
    try {
      console.log("Checking owner");
      const connectedContract = connectToContract();
      const id = await connectedContract.tokenIdByFEN(encodeURIComponent(fen));
      console.log(id);
      const address = await connectedContract.ownerOf(id);
      console.log(address);
      setTokenId(id);
      setOwner(<span>{address}</span>);
      setShowOpenSea(true);
    } catch (error) {
      console.log(error);
      setOwner(<b>This NFT is not owned by anyone.</b>);
    }
  };

  useEffect(() => {
    console.log(state);
    getOwner(state.fen);
  }, []);

  return (
    <div className={styles.container}>
      {/*<head>
        <title>ChessNFT</title>
        <meta name="description" content="ChessNFT" />
        <link rel="icon" href="/favicon.ico" />
      </head>*/}

      <main className={styles.main}>
        <div className={styles.grid}>
          <Link to="/" >
          <img src="/chezzbit_2_Logo.png" style={{width: "340px", cursor: "pointer"}} alt="Chezzbit Logo" />
          </Link>

          <div style={{opacity: 1, marginTop: "30px", marginBottom: "10px", fontWeight: "normal"}}></div>

          <Chessboard id="chessboard" arePiecesDraggable={false} position={(state||{}).fen} customBoardStyle={{border: "2px solid black", borderRadius: "0px", padding: "10px", display: "inline-table", background: "white"}} boardWidth={320} />

          <div style={{opacity: 1, marginTop: "30px", marginBottom: "00px", fontWeight: "normal", overflow: "scroll"}}>FEN number:</div>
          <div style={{opacity: 1, marginTop: "0px", marginBottom: "10px", fontWeight: "normal", wordBreak: "break-all"}}>{(state||{}).fen}</div>

          <div style={{opacity: 1, marginTop: "10px", marginBottom: "0px", fontWeight: "normal"}}>Owned by wallet:</div>
          <div style={{opacity: 1, marginTop: "0px", marginBottom: "10px", fontWeight: "normal", wordBreak: "break-all"}}>{owner}</div>

          <div style={{display: showOpenSea ? "block" : "none"}}>
            <div style={{opacity: 1, marginBottom: "10px", marginTop: "30px"}}>Buy on secondary market:</div>
            <a href={`https://testnets.opensea.io/assets/mumbai/${CONTRACT_ADDRESS}/${tokenId}`} className={styles.opensealink} >
              <button className={styles.openseabutton}
                onMouseOver={e => e.currentTarget.children[0].src = "/OpenSea-Blue.svg"}
                onMouseOut={e => e.currentTarget.children[0].src = "/OpenSea-White.svg"}
              >
                <img src='/OpenSea-White.svg' alt="OpenSea" />
              </button>
            </a>
          </div>

          <div style={{opacity: 1, marginBottom: "0px", marginTop: "10px"}}>
            { showOpenSea ? "Or m" : "M" }
            int another NFT:
          </div>
          <div className={styles.card} style={{ width: "100%" }}>
            <Link to="/">
            <div className={styles.centered}>
            <button className={styles.mainbutton}>
              Mint your own NFT? <span style={{fontSize: "20px", marginTop: "-5px", marginLeft: "6px"}}>&#9813;</span>
            </button>
            </div>
            </Link>

          </div>
        </div>
      </main>
    </div>
  );
};
